import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleGrid(e) {
    if (e.key === 'g' && document.activeElement !== 'text') {
      e.preventDefault()
      this.element.classList.toggle('is-visible')
    }
  }
}
