import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets() {
    return ['slides', 'slide', 'slideBg', 'slideIndicator', 'title', 'blurb']
  }

  connect() {
    this.boundScroll()
    this.setSlidesPadding()
  }

  boundScroll() {
    const slidesRect = this.slidesTarget.getBoundingClientRect()

    this.slideTargets.forEach((slide, idx) => {
      const slideRect = slide.getBoundingClientRect()
      const slideOffsetLeft = slideRect.x - slidesRect.x
      const isSlideInViewport = slideOffsetLeft >= -slidesRect.width / 2 && slideOffsetLeft < slidesRect.width / 2

      slide.classList.toggle('is-selected', isSlideInViewport)

      this.moveSlideBackground()

      // Update navigation indicator
      this.slideIndicatorTargets[idx].classList.toggle('is-selected', isSlideInViewport)

      if (isSlideInViewport) {
        this.titleTarget.textContent = slide.dataset.title
        this.blurbTarget.textContent = slide.dataset.blurb
      }
    })
  }

  moveSlideBackground() {
    const slidesRect = this.slidesTarget.getBoundingClientRect()

    this.slideTargets.forEach((slide, idx) => {
      const slideRect = slide.getBoundingClientRect()
      const bg = this.slideBgTargets[idx]
      const d = (slideRect.left - slidesRect.left) / slidesRect.width
      bg.style.transform = 'translate(' + (-bg.offsetWidth / 2 - (bg.offsetWidth - slideRect.width) * d) + 'px, -50%)'
    })
  }

  boundResize() {
    this.setSlidesPadding()
    this.moveSlideBackground()
  }

  setSlidesPadding() {
    this.slidesTarget.style.paddingRight = `${
      this.slidesTarget.offsetWidth - this.slideTargets[this.slideTargets.length - 1].offsetWidth
    }px`
  }

  scrollToNextSlide() {
    const selectedSlide = this.slidesTarget.querySelector('.is-selected')
    if (selectedSlide) {
      const nextElement = selectedSlide.nextElementSibling
      if (nextElement) nextElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  scrollToPrevSlide() {
    const selectedSlide = this.slidesTarget.querySelector('.is-selected')
    if (selectedSlide) {
      const prevElement = selectedSlide.previousElementSibling
      if (prevElement) prevElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  scrollToSlide(e) {
    const el = e.currentTarget
    const idx = [...el.parentElement.children].indexOf(el)
    this.slideTargets[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }
}
