import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.moveElement.bind(this))
  }

  moveElement() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    this.element.style.transform = `translateY(${-scrollTop / 3}px)`
  }
}
