import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets() {
    return ['slides', 'slide', 'description', 'slideIndicator', 'pager', 'slideIndicators']
  }

  connect() {
    this.initSlideControls()
    this.observeSlideClassChange()
    this.observeGalleryInViewport()
    this.boundScroll()
  }

  initSlideControls() {
    if (this.slideTargets.length === 1) {
      this.slideIndicatorsTarget.classList.add('is-hidden')
      this.pagerTarget.classList.add('is-hidden')
    }
  }

  boundScroll() {
    const slidesRect = this.slidesTarget.getBoundingClientRect()

    this.slideTargets.forEach((slide, idx) => {
      const slideRect = slide.getBoundingClientRect()
      const slideOffsetLeft = slideRect.x - slidesRect.x
      const isSlideInViewport = slideOffsetLeft >= -slidesRect.width / 2 && slideOffsetLeft <= slidesRect.width / 2

      slide.classList.toggle('is-selected', isSlideInViewport)

      // Update navigation indicator
      this.slideIndicatorTargets[idx].classList.toggle('is-selected', isSlideInViewport)

      if (isSlideInViewport) {
        this.descriptionTarget.textContent = `${idx + 1} — ${slide.dataset.description}` || ''

        this.descriptionTarget.classList.toggle('is-hidden', slide.dataset.description == '')

        if (slide.dataset.bgcolor) {
          this.slidesTarget.style.backgroundColor = slide.dataset.bgcolor
        }
      }
    })
  }

  observeGalleryInViewport() {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      const el = entries[0]
      this.slideTargets.forEach((slide) => {
        if (slide.classList.contains('is-selected') && slide.tagName == 'VIDEO') {
          if (el.isIntersecting) {
            if (slide.readyState === 4) {
              slide.play()
            }
          } else {
            slide.pause()
          }
        }
      })
    })

    this.intersectionObserver.observe(this.element)
  }

  observeSlideClassChange() {
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const target = mutation.target
        if (mutation.attributeName === 'class' && target.tagName == 'VIDEO') {
          // eslint-disable-next-line no-unused-expressions
          if (target.classList.contains('is-selected')) {
            if (target.readyState === 4) {
              target.play()
            }
          } else {
            target.pause()
          }
        }
      })
    })

    const observeOptions = {
      attributes: true,
    }

    this.slideTargets.forEach((slide) => {
      // observe slide class change
      this.mutationObserver.observe(slide, observeOptions)
    })
  }

  scrollToNextSlide() {
    const selectedSlide = this.slidesTarget.querySelector('.is-selected')
    if (selectedSlide) {
      const nextElement = selectedSlide.nextElementSibling
      if (nextElement) nextElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  scrollToPrevSlide() {
    const selectedSlide = this.slidesTarget.querySelector('.is-selected')
    if (selectedSlide) {
      const prevElement = selectedSlide.previousElementSibling
      if (prevElement) prevElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  scrollToSlide(e) {
    const el = e.currentTarget
    const idx = [...el.parentElement.children].indexOf(el)
    this.slideTargets[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  disconnect() {
    if (this.mutationObserver) this.mutationObserver.disconnect()
  }
}
