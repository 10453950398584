import { Controller } from '@hotwired/stimulus'
import { trigger } from '../../js/utils'

export default class extends Controller {
  static get targets() {
    return ['item']
  }

  static get values() {
    return {
      threshold: Number,
      autoHide: Boolean,
      trigger: String,
    }
  }

  connect() {
    window.addEventListener('scroll', this.revealElement.bind(this))
    this.revealElement()
  }

  revealElement() {
    const threshold = this.thresholdValue
    const bounds = this.element.getBoundingClientRect()
    if (
      bounds.top - window.innerHeight - threshold <= 0 &&
      bounds.bottom + threshold >= 0 &&
      !this.element.classList.contains('is-visible')
    ) {
      this.element.classList.add('is-visible')
      if (this.triggerValue) {
        trigger(this.triggerValue)
      }
    }

    if (
      (bounds.top - window.innerHeight - threshold > 0 || bounds.bottom + threshold < 0) &&
      this.element.classList.contains('is-visible') &&
      this.autoHideValue
    ) {
      this.element.classList.remove('is-visible')
    }
  }
}
