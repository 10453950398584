export const isTouchDevice = () =>
  'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0

export const trigger = (eventId, obj = {}, scope = document) => {
  const event = new CustomEvent(eventId, {
    detail: obj,
  })
  scope.dispatchEvent(event)
}

export const click = () => (isTouchDevice() ? 'touchstart' : 'click')

export const getURLParams = () => Object.fromEntries(new URLSearchParams(window.location.search.substring(1)))

export const stripTags = (str) => {
  const element = document.createElement('div')
  element.innerHTML = str
  return element.innerText
}

export const onClassChange = (node, callback) => {
  const classObserver = new window.MutationObserver(() => {
    callback(node)
  })
  classObserver.observe(node, {
    attributes: true,
    attributeFilter: ['class'],
  })
}

export const rgb2hex = (r, g, b) =>
  '#' +
  ('0' + parseInt(r, 10).toString(16)).slice(-2) +
  ('0' + parseInt(g, 10).toString(16)).slice(-2) +
  ('0' + parseInt(b, 10).toString(16)).slice(-2)
