import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets() {
    return ['toggle']
  }

  connect() {
    document.addEventListener('pageResize', this.switchLayout.bind(this))
  }

  toggleNav() {
    this.element.classList.add('is-animated')
    this.element.classList.toggle('is-visible')
  }

  switchLayout() {
    if (window.innerWidth > 559) {
      this.element.classList.remove('is-animated')
      this.element.classList.remove('is-visible')
    }
  }
}
