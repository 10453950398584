import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets() {
    return ['heroWrapper', 'contentWrapper']
  }

  connect() {
    // console.log('xxx xxx')
  }
}
