import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    setTimeout(this.hideCover.bind(this), 500)
  }

  hideCover(e) {
    // History back cache detection
    if ((e && e.persisted) || e === undefined) {
      this.element.classList.remove('is-visible')
    }
  }
}
